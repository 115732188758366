<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4">
        <h2>FAQ</h2>
      </v-col>
    </v-row>

    <template>
      <v-row class="mb-2" justify="center">
        <v-expansion-panels inset>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>What is the Herbert Hustle Award?</strong>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              The Herbert Hustle Award is considered the most prestigious award
              an athlete can recieve in modern day athletics. This award is
              bestowed to the athlete who displays uncanny effort, heart and
              passion during the "T3".
              <br />
              <br />
              <p>
                All recipents of this award must recieve uniamous votes from the
                "T3" committee.
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong
                >Will the "T3" be televised? ({{
                  new Date().getFullYear()
                }})</strong
              >
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              Not this year. Although, we are working on a deal with ESPN the
              Ocho for next year!
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>Who are the previous "T3" Award winners?</strong>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <Timeline />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong
                >Can non Born Again Strength member(s) participate?</strong
              >
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              Yes! We encouge everyone to play in the "T3".
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <strong>Can I come just to watch the tournament?</strong>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              Yes. We encourage everyone to come hangout. We will also have a
              n64 set up to play old school games while players wait for their
              next game.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
  </div>
</template>

<script>
import Timeline from '@/components/Timeline.vue'
export default {
  components: { Timeline },
}
</script>

<style></style>

<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img class="dark align-center" :src="TThreeLogo"></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h1>What is it:</h1>
          <p>
            The Turkey Turkey Turkey Spikeball Tournment or "T3" for short is a
            Spikeball Tournament with the goal to collect canned foods for
            families in need during the Thanksgiving hoildays.
          </p>
        </v-col>
        <v-col cols="12" sm="6">
          <h1>Admission:</h1>
          <p>
            To participate in the "T3" each athlete is asked to donate at least
            1 canned good.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <h1>Awards:</h1>
          <p>Open Champions (All Ages)</p>
          <p>Youth Champions (10-14yrs)</p>
          <p>Herbert Hustle Award</p>
        </v-col>
        <v-col cols="12" sm="6">
          <h1>How To Play:</h1>

          <p>Send us an email with your team name, age group, and roster.</p>
          <ContactButton title="Sign Up" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h1>Previous Tournaments</h1>
          <v-card
            hover
            class="mx-auto"
            max-width="340"
            :to="{ name: 'FirstAnnualT3' }"
          >
            <v-img
              class="dark align-center"
              src="/images/T3Group2021.JPG"
              contain
            />
            <v-card-text class="text--primary text-left">
              <h3 class="mt-2">1st Annual "T3" Spikeball Tournament</h3>
              <h5 class="grey-text">November 13th, 2021 - Stone Osborne</h5>
              <p class="mt-2">
                The 1st Annual "T3" Spikeball Tournament boasted a 14 team open
                style double elimaination tournament that included pool play
                for....

                <v-btn text color="blue" :to="{ name: 'FirstAnnualT3' }"
                  >READ MORE</v-btn
                >
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <T3FAQ />
    </v-container>
  </div>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue'
import T3FAQ from '@/components/T3FAQ.vue'
import TThreeLogo from '@/assets/images/T3Logo.svg'
export default {
  components: { ContactButton, T3FAQ },
  data() {
    return {
      TThreeLogo: TThreeLogo,
    }
  },
}
</script>

<style></style>
